import '../css/back.scss';

import 'bootstrap';
import toastr from 'toastr';
import 'select2';
import "select2/dist/js/i18n/es";
import 'chart.js/dist/chart';
import '../../public/bundles/tetranzselect2entity/js/select2entity';
import './sb-admin-2';

import $ from 'jquery';

global.Back = {

    chart: function() {
        $('.chart').each((index, item) => {
            const ctx = document.getElementById( $(item).attr('id') );
            const myChart = new Chart(ctx, {
                type: $(item).data('chart-type'),
                data: $(item).data('chart'),
                options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    legend: {
                        display: false,
                    },
                },
            });
        });
    },

    formAjax: function() {

        $(document).on('submit', 'form.formajax', function(e) {
            var postData = $(this).serializeArray();
            var action = $(this).attr('action');
            var target = $(this).data('target') + ' .modal-content';
            var redirect = $(this).data('redirect');

            $.ajax({
                url : action,
                type : "POST",
                data : postData,
                success : function(data)
                {
                    if (data == "OK") {
                        window.location.replace(redirect);
                    } else {
                        $(target).html(data);
                    }
                }
            });

            e.preventDefault(); //STOP default action
        });

    },

    linkRemote: function() {

        $('[data-load-remote]').on('click', function(e) {
            e.preventDefault();

            let $this = $(this);
            let $target = $this.data('remote-target');
            let $remote = $this.data('load-remote');
            let $remove = $this.data('elem-remove');

            if ($remote) {
                $($target).html("");

                $($remove).remove();

                $.get($remote, function (data) {
                    $($target).append(data);
                });
            }
        });

    },

    toastrRun: function(tipo, mensaje) {

        toastr.options = {
            closeButton: true,
            progressBar: true,
            showMethod: 'slideDown',
            positionClass: 'toast-top-center',
            timeOut: 5000
        };

        setTimeout(function() {
            switch (tipo) {
                case 'error':
                    toastr.error(mensaje);
                    break;
                case 'success':
                    toastr.success(mensaje);
                    break;
                case 'warning':
                    toastr.warning(mensaje);
                    break;
                case 'info':
                    toastr.info(mensaje);
                    break;
            }
        }, 1000);

    },

    selectDependientes: function() {

        $(document).on('change', '.select-origen', function(e) {
            var $selectOrigen = $(this);
            var $infoOrigen = $(this).closest('.select-dependiente');

            if ($infoOrigen.data('dependiente')) {
                var $selectDependiente = $($infoOrigen.data('dependiente')).find('select');
                var $urlDependiente = $infoOrigen.data('dependiente-url');

                if ($selectDependiente.hasClass('select-origen')) {
                    var $infoOrigenDependiente = $selectDependiente.closest('.select-dependiente');

                    if ($infoOrigenDependiente.data('dependiente')) {
                        var $selectDependienteDependiente = $($infoOrigenDependiente.data('dependiente')).find('select');
                        $selectDependienteDependiente.val(null);
                        $selectDependienteDependiente
                            .find('option')
                            .not(':first')
                            .remove()
                            .end();
                    }
                }

                cargaSelectDependiente($selectDependiente, $urlDependiente);
            }

            if ($infoOrigen.data('dependiente2')) {
                var $selectDependiente2 = $($infoOrigen.data('dependiente2')).find('select');
                var $urlDependiente2 = $infoOrigen.data('dependiente2-url');

                cargaSelectDependiente($selectDependiente2, $urlDependiente2);
            }
        });

        function cargaSelectDependiente(selectDependiente, url)
        {
            var entityId = null;
            var entityId2 = null;

            var depende = selectDependiente.closest('.select-dependiente').data('depende');
            if (depende) {
                var selectDepende = $(depende).find('select');
                entityId = selectDepende.val();
            }

            var depende2 = selectDependiente.closest('.select-dependiente').data('depende2');
            if (depende2) {
                var selectDepende2 = $(depende2).find('select');
                entityId2 = selectDepende2.val();
            }

            var data = {
                entityId: entityId,
                entityId2: entityId2
            };

            $.ajax({
                type: 'post',
                url: url,
                data: data,
                success: function(data) {
                    selectDependiente
                        .find('option')
                        .not(':first')
                        .remove()
                        .end();

                    var total = data.length;

                    for (var i = 0; i < total; i++) {
                        //console.log(data[i].value+" - "+data[i].label);
                        selectDependiente.append('<option value="' + data[i].value + '">' + data[i].label + '</option>');
                    }
                }
            });
        }

    },

    init: function() {
        this.chart();
        this.formAjax();
        this.linkRemote();
        this.selectDependientes();
        $('[data-toggle="tooltip"]').tooltip();
    }

};

$(function() {
    Back.init();
});
